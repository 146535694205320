import {
    AppState,
    NavEvent,
    QuickLinks,
    SelectItemAvailableEvent,
    ViewItemListAvailableEvent,
    BannerEvent,
} from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledAd } from '../TheNightlyStickySiteHeader/TheNightlyStickySiteHeader.styled'
import { DimensionProps, withDimensions } from '../../__helpers/with-dimensions'

import {
    ProviderChildProps,
    TNHeaderNav,
    useTheNightlyHeaderNav,
} from '../../navigation'

export interface TheNightlyStickySiteHeaderProps {
    headerAd?: (rendered?: () => void) => JSX.Element | undefined
    onEvent: (
        event:
            | NavEvent
            | SelectItemAvailableEvent
            | ViewItemListAvailableEvent
            | BannerEvent,
    ) => void
    navigation: JSX.Element
    quickLinks: QuickLinks
    flyOutProps?: ProviderChildProps
}

type InternalProps = TheNightlyStickySiteHeaderProps & DimensionProps

export const InternalTheNightlyStickySiteHeader: React.FC<InternalProps> = ({
    headerAd,
    onEvent,
    navigation,
    quickLinks,
    flyOutProps,
    triggerMeasure,
    height,
    innerRef,
}) => {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    const navState = useTheNightlyHeaderNav({
        onEvent,
        flyOutProps,
    })

    if (height !== navState.adHeight) {
        navState.updateAdHeight(height)
    }

    const disableHeaderAd = renditionType === 'app'

    return (
        <>
            <TNHeaderNav
                navState={navState}
                navigation={navigation}
                quickLinks={quickLinks}
                onEvent={onEvent}
                adComponent={!disableHeaderAd ? renderHeaderAd() : undefined}
            />
        </>
    )

    function renderHeaderAd() {
        return (
            headerAd && (
                <StyledAd className="headerAdvertisement" ref={innerRef}>
                    {headerAd(() => triggerMeasure())}
                </StyledAd>
            )
        )
    }
}

export const TheNightlyStickySiteHeader =
    withDimensions<TheNightlyStickySiteHeaderProps>(
        InternalTheNightlyStickySiteHeader,
    )

TheNightlyStickySiteHeader.displayName = 'TheNightlyStickySiteHeader'
