import React, { FC } from 'react'
import { AllEvents, PublicationCardItem } from '@news-mono/web-common'
import {
    StyledArticleListContainer,
    StyledBreakingNewsContainer,
} from './TNBreakingNews.styled'
import { LeadCard } from '../../cards/TheNightly/LeadCard/LeadCard'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { Luna } from '../../compositions/Luna/Luna'
import { Juliet } from '../../collections/Juliet/Juliet'
import { TNBreakingNewsHeadline } from './components/TNBreakingNewsHeadline'
import { useViewport } from '../../__helpers/use-viewport'

interface TNBreakingNewsProps {
    mainArticle: PublicationCardItem
    articles: PublicationCardItem[]
    headline: string
    kicker?: string
    onEvent: (event: AllEvents) => void
}

const TEASER_DISPLAY_CUTOFF = 3
const TEASERS_TO_SHOW = 2

export const TNBreakingNews: FC<TNBreakingNewsProps> = ({
    headline,
    mainArticle,
    articles,
    kicker,
    onEvent,
}) => {
    const { isLargeScreen } = useViewport()

    if (articles.length === 0) {
        return (
            <StyledBreakingNewsContainer isSingleArticleCuration={true}>
                <TNBreakingNewsHeadline headline={headline} kicker={kicker} />
                <LeadCard item={loadedData(mainArticle)} onEvent={onEvent} />
            </StyledBreakingNewsContainer>
        )
    }

    const showTeaser = articles.length <= TEASER_DISPLAY_CUTOFF && isLargeScreen

    const content = {
        beforeAside: (
            <LeadCard item={loadedData(mainArticle)} onEvent={onEvent} />
        ),
        aside: (
            <StyledArticleListContainer>
                <Juliet
                    items={loadedData(articles)}
                    onEvent={onEvent}
                    expectedCards={articles.length}
                    cardType={{
                        type: 'landscape',
                        format: 'landscape-common',
                    }}
                    verticalSpacing={'md'}
                    removeHorizontalGutters={true}
                    hideByline={false}
                    topicLevel={'parent'}
                    hideLastDivider={true}
                    removeTopListPadding={true}
                    showTeaser={showTeaser}
                    numberOfTeasersToShow={TEASERS_TO_SHOW}
                    noHorizontalDividers={!isLargeScreen}
                />
            </StyledArticleListContainer>
        ),
        afterAside: <></>,
    }

    return (
        <StyledBreakingNewsContainer isSingleArticleCuration={false}>
            <TNBreakingNewsHeadline headline={headline} kicker={kicker} />
            <Luna
                content={content}
                asideSide="right"
                hasBeforeAside={true}
                hasAfterAside={false}
                dividerConfig={{
                    column: {
                        hasBeforeAsideAndAsideDivider: false,
                    },
                    split: {
                        hasVerticalDivider: false,
                    },
                }}
            />
        </StyledBreakingNewsContainer>
    )
}

const loadedData = <T,>(data: T): MaybeLoaded<T> => {
    return {
        loaded: true,
        result: data,
    }
}
