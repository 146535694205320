import { AdTargeting, Features } from '@news-mono/web-common'
import { sectionThemeOverrider } from '@news-mono/component-library'
import { layout } from '../../App.routing'
import {
    getCombinedCardListCurationData,
    getCombinedCurationListingData,
} from './get-topic-segment'

type OlympicsSectionProps = {
    /** A unique identifier for the adSlots in this section. */
    adPos?: number
    /** Targeting info for the advertisement. */
    adTargeting?: AdTargeting
    /** If the aside advert should be shown. */
    showAsideAdvert?: boolean
    /** Conditional toggle for render of section */
    feature?: Features
    /** Custom ID, usually used for logging purposes */
    debuggingCustomId?: string
}

export const olympicsSection = ({
    adPos,
    adTargeting,
    showAsideAdvert = true,
    feature,
    debuggingCustomId,
}: OlympicsSectionProps) => {
    const section = 'sport'
    const curation = 'olympics'

    return layout.composition({
        type: 'scheme-overrider',
        props: {
            override: sectionThemeOverrider('thenightly', section),
        },
        feature: feature,
        contentAreas: {
            children: [
                layout.nestedComposition({
                    type: 'section-content',
                    props: {
                        section: 'sport',
                        heading: 'Paralympics',
                    },
                    contentAreas: {
                        children: [
                            layout.component({
                                type: 'section-header',
                                props: {
                                    heading: 'Paralympics',
                                    headingUrl: '/sport/paralympics',
                                },
                            }),
                            layout.nestedComposition({
                                type: 'luna',
                                props: { hasAfterAside: false },
                                contentAreas: {
                                    beforeAside: [
                                        layout.component({
                                            type: 'article-card-list',
                                            props: {
                                                dataDefinitionArgs:
                                                    getCombinedCurationListingData(
                                                        {
                                                            curation: {
                                                                id: curation,
                                                                itemCount: 8,
                                                            },
                                                            topic: section,
                                                            offset: 0,
                                                            pageSize: 3,
                                                        },
                                                    ),
                                                expectedItemCount: 3,
                                                adTargeting: adTargeting,
                                                debuggingCustomId,
                                            },
                                        }),
                                    ],
                                    aside: [
                                        layout.nestedComposition({
                                            type: 'box',
                                            props: {
                                                horizontalSpacing:
                                                    'outerMargin',
                                                horizontalSpacingBreakpoint:
                                                    'sm',
                                                flex: {
                                                    default: {
                                                        flexGrow: 1,
                                                        flexDirection: 'column',
                                                    },
                                                },
                                            },
                                            contentAreas: {
                                                main: [
                                                    getCombinedCardListCurationData(
                                                        {
                                                            curation: {
                                                                id: curation,
                                                                itemCount: 8,
                                                            },
                                                            topic: section,
                                                            offset: 3,
                                                            pageSize: 5,
                                                            title: 'Latest Paralympics news',
                                                        },
                                                    ),
                                                    ...(showAsideAdvert
                                                        ? [
                                                              layout.component({
                                                                  type: 'ad-unit',
                                                                  props: {
                                                                      adType: 'inline',
                                                                      noticePosition:
                                                                          'above-center',
                                                                      slot: {
                                                                          id: `${adPos}-mrec-${section}`,
                                                                          size: 'above1280Mrec',
                                                                      },
                                                                      stickyOffset:
                                                                          'calc(var(--stickyHeaderHeight) + 80px)',
                                                                  },
                                                              }),
                                                          ]
                                                        : []),
                                                ],
                                            },
                                        }),
                                    ],
                                    afterAside: [],
                                },
                            }),
                        ],
                    },
                }),
            ],
        },
    })
}
