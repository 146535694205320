import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling/'
import { zIndex } from '../../../__styling/settings/z-index'

interface StyledAnimatedContentNavProps {
    transitionTime: number
    hasHeaderNav: boolean
    navHeight: number
    contentNavHeight: number
    bannerHeight: number
    isVisible: boolean
}

const DESKTOP_SPACING = 32
const BANNER_SPACING = 8
const TOP_HIDDEN = -500 // make sure it is hidden from the top of browser

export const StyledAnimatedContentNav =
    styled.div<StyledAnimatedContentNavProps>(
        ({
            transitionTime,
            hasHeaderNav,
            navHeight,
            bannerHeight,
            contentNavHeight,
            isVisible,
        }) => {
            const transition = `opacity ${transitionTime}ms ease-out`
            const topMobile = hasHeaderNav ? navHeight : 0
            const topDesktop = bannerHeight
                ? bannerHeight + BANNER_SPACING
                : DESKTOP_SPACING
            const zIndexNav = zIndex.thenightly.stickySiteHeader - 1
            const translateY = isVisible ? 0 : TOP_HIDDEN

            return {
                // Safari does not like sticky
                position: 'fixed',
                overflowX: 'auto',
                width: '100%',
                top: calcRem(topMobile),
                zIndex: zIndexNav,
                display: 'flex',
                height: isVisible ? navHeight : 0,
                // Translate3d for Safari...
                transform: `translate3d(0px ,${translateY}px, 1px)`,

                [breakpoint('sm')]: {
                    top: calcRem(topDesktop),
                },

                // Content nav is opening. Fade in from the top
                '&.content-nav-enter': {
                    transform: `translate3d(0px, ${-contentNavHeight}px, 1px)`,
                    opacity: 0,

                    [breakpoint('sm')]: {
                        transform: `translate3d(0px, ${-DESKTOP_SPACING}px, 1px)`,
                    },
                },

                '&.content-nav-enter-active': {
                    transform: `translate3d(0px, 0px, 0px)`,
                    transition,

                    [breakpoint('sm')]: {
                        opacity: 0.99,
                    },
                },
            }
        },
    )
