import { TheNightlySection } from '@news-mono/common'
import {
    AuthenticationProvider,
    CookieConsentManager,
    DebugAdUnitsList,
    FeatureToggle,
    FlyoutNavStateProvider,
    GoogleNativeAppPrompt,
    HeaderWrapper,
    isTabletOrMobileBrowser,
    LiveRampATS,
    RecaptchaProvider,
    ScrollToTopOnNav,
    TaboolaScriptProvider,
    TNAuthenticatedNewsletterSignup,
    UserAnalytics,
} from '@news-mono/component-library'
import {
    AdDefinition,
    AddToCartEvent,
    RemoveFromCartEvent,
    AdEvent,
    AdState,
    BaseClientConfig,
    BreachScreenContextProvider,
    CookieConsentEvent,
    ExtendedAccessEvent,
    NavEvent,
    Product,
    RadioEvent,
    SubscribeEvent,
    SubscribeWithGoogleEvent,
    usePrevious,
    UserAuthEvents,
    ViewCartEvent,
    RenderTargetContext,
    FilterContextProvider,
    LinkClickedEvent,
    SelectItemAvailableEvent,
    ViewItemListAvailableEvent,
    BannerEvent,
} from '@news-mono/web-common'
import debug from 'debug'
import H from 'history'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { layout } from './App.routing'
import { BaseTheNightly } from './BaseTheNightly/BaseTheNightly'
import { StyledSite } from './Site.styled'
import { QueryClient, QueryClientProvider } from 'react-query'
import { NewsletterSignupEvent } from 'libs/web-common/src/events/newsletter-signup-events'

export const locationChangeDebug = debug('location-change')

export interface SiteProps {
    pageContents: React.ReactElement<any>
    pageType: string
    additionalPageProperties: { [key: string]: any }
    location: H.Location
    onEvent: (
        event:
            | NavEvent
            | AdEvent
            | UserAuthEvents
            | SubscribeEvent
            | CookieConsentEvent
            | ExtendedAccessEvent
            | SubscribeWithGoogleEvent
            | RadioEvent
            | AddToCartEvent
            | ViewCartEvent
            | RemoveFromCartEvent
            | NewsletterSignupEvent
            | SelectItemAvailableEvent
            | ViewItemListAvailableEvent
            | BannerEvent,
    ) => void
    config: BaseClientConfig
    hostname: string
    ads: AdState | undefined
    section: TheNightlySection
}

export interface State {
    mobileNavExpanded: boolean
}

const headerUnit: AdDefinition = {
    id: 'header',
    size: 'leaderboard768Above',
    isSitewideAd: true,
}
const skinsUnit: AdDefinition = {
    id: 'truskin',
    size: 'skins',
    isSitewideAd: true,
}
const stickyFooterUnit: AdDefinition = {
    id: 'sticky-footer',
    size: 'belowDesktopLeaderboard',
}

const twlStickyPlayerUnit: AdDefinition = {
    id: 'twl-player',
    size: 'theWestLiveLogo',
}
const twlLandingPageUnit: AdDefinition = {
    id: 'twl-landing',
    size: 'theWestLiveLogo',
}
const twlBannerPageUnit: AdDefinition = {
    id: 'twl-banner',
    size: 'theWestLiveLogo',
}

export const siteAds: AdDefinition[] = [
    headerUnit,
    skinsUnit,
    stickyFooterUnit,
    twlStickyPlayerUnit,
    twlLandingPageUnit,
    twlBannerPageUnit,
]

// This emulates the ads rendered above the content in this file for the ad-diagnostics page
export const headerCompositions = [
    layout.composition({
        type: 'thor',
        props: {},
        contentAreas: {
            main: [
                layout.component({
                    type: 'ad-unit',
                    props: {
                        noticePosition: 'top-right',
                        slot: headerUnit,
                        adType: 'inline',
                    },
                }),
            ],
        },
    }),
]

export const footerCompositions = [
    layout.composition({
        type: 'thor',
        props: {},
        contentAreas: {
            main: [
                layout.component({
                    type: 'ad-unit',
                    props: {
                        noticePosition: 'below-center',
                        slot: stickyFooterUnit,
                        adType: 'inline',
                    },
                }),
            ],
        },
    }),
]

export const SetupLocalHistoryDebug: React.FC = () => {
    const history = useHistory()
    useEffect(() => {
        history.listen((location) => {
            locationChangeDebug('Detecting location change...', [location])
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}

export const Site: React.FC<SiteProps> = ({
    config,
    location,
    ads,
    section,
    onEvent,
    pageContents,
    additionalPageProperties,
    pageType,
    hostname,
}) => {
    const previousLocation = usePrevious(location)
    const queryClient = new QueryClient()
    const { renderTarget } = React.useContext(RenderTargetContext)

    const isMobileOrTabletBrowser = isTabletOrMobileBrowser(renderTarget)

    // Smartocto Integration
    React.useEffect(() => {
        if (window && location !== previousLocation) {
            setTimeout(() => window.postMessage('activateTentacles'), 3000)
        }
    }, [location, previousLocation])

    const postWebViewMessageHandler = (data: string) => {
        window.ReactNativeWebView?.postMessage(data)
    }

    const baseNightly = (
        <BreachScreenContextProvider>
            <AuthenticationProvider
                postWebViewMessage={postWebViewMessageHandler}
            >
                <TaboolaScriptProvider>
                    <FilterContextProvider>
                        <SetupLocalHistoryDebug />

                        {config.recaptureSiteKey && (
                            <RecaptchaProvider
                                siteKey={config.recaptureSiteKey}
                            >
                                <QueryClientProvider client={queryClient}>
                                    <TNAuthenticatedNewsletterSignup
                                        onEvent={onEvent}
                                    />
                                </QueryClientProvider>
                            </RecaptchaProvider>
                        )}

                        <FlyoutNavStateProvider>
                            {(flyOutProps) => (
                                <BaseTheNightly
                                    additionalPageProperties={
                                        additionalPageProperties
                                    }
                                    ads={ads}
                                    config={config}
                                    flyOutProps={flyOutProps}
                                    location={location}
                                    onEvent={onEvent}
                                    pageContents={pageContents}
                                    pageType={pageType}
                                    product={Product.TheNightly}
                                    section={section}
                                    hostname={hostname}
                                />
                            )}
                        </FlyoutNavStateProvider>
                    </FilterContextProvider>
                </TaboolaScriptProvider>
            </AuthenticationProvider>
        </BreachScreenContextProvider>
    )
    return (
        <React.Fragment>
            {isMobileOrTabletBrowser && (
                <FeatureToggle
                    feature="app-smart-banner-android"
                    on={() => <GoogleNativeAppPrompt />}
                />
            )}
            <UserAnalytics />
            <LiveRampATS />
            <HeaderWrapper section={section} product={Product.TheNightly} />
            <StyledSite>
                <ScrollToTopOnNav />
                {baseNightly}
                <FeatureToggle
                    feature="debug-ads"
                    on={() => (
                        <DebugAdUnitsList
                            ads={ads}
                            pathname={location.pathname}
                        />
                    )}
                />
            </StyledSite>
            <FeatureToggle
                feature="cookie-consent"
                on={() => (
                    <CookieConsentManager
                        onEvent={onEvent}
                        product={Product.TheNightly}
                    />
                )}
            />
        </React.Fragment>
    )
}
Site.displayName = 'Site'
