import {
    EditionListV4ResponseDTO,
    ListPublicationWithEntriesV4DTO,
} from '@west-australian-newspapers/publication-types'
import { useEffect, useState } from 'react'
import { EditorialType, getEditorialType } from '../../templates'
import {
    BaseClientConfig,
    mapListPublication,
    PublicationCardItemWithoutVideo,
    useFeature,
} from '@news-mono/web-common'
import { format, subHours } from 'date-fns'
import fetch from 'node-fetch'

export interface NewsTickerItem {
    text: string
    link: string
    type?: EditorialType
    withGradientBackground: boolean
}

export const useNewsTicker = (config: BaseClientConfig) => {
    const latestEditionThresholdInHour = 24

    const [isLoading, setIsLoading] = useState(false)
    const [newsTickerItems, setNewsTickerItems] = useState<NewsTickerItem[]>()

    const addEditionToNewsTickerEnabled = useFeature(
        'add-edition-to-news-ticker',
    )

    useEffect(() => {
        fetchNewsTickerCuration(config)
            .then(async (result) => {
                setIsLoading(true)
                const mappedNewsTickerItems: PublicationCardItemWithoutVideo[] =
                    result.articles.map(
                        (listPublication: ListPublicationWithEntriesV4DTO) =>
                            mapListPublication(listPublication),
                    )

                const newList = combineArticlesAndAdditional(
                    mappedNewsTickerItems,
                    result.metadata,
                )

                // add the latest edition to news ticker
                if (addEditionToNewsTickerEnabled) {
                    const editionNewsTicker = await getLatestEditionNewsTicker(
                        config,
                        latestEditionThresholdInHour,
                    )

                    if (editionNewsTicker) newList.push(editionNewsTicker)
                }

                setNewsTickerItems(newList)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [addEditionToNewsTickerEnabled, config])

    return { isLoading, newsTickerItems }
}

const getLatestEditionNewsTicker = async (
    config: BaseClientConfig,
    latestEditionThresholdInHour: number,
): Promise<NewsTickerItem | undefined> => {
    const editionList = (await fetchEdition(config)) as EditionListV4ResponseDTO

    const now = new Date()
    const latestPublicationTimeThreshold = subHours(
        now,
        latestEditionThresholdInHour,
    )
    const latestEdition = editionList.documents.filter(
        (edition) =>
            new Date(edition.publicationDate) > latestPublicationTimeThreshold,
    )

    if (latestEdition && latestEdition.length > 0) {
        const editionText = `LATEST EDITION: ${format(
            new Date(latestEdition[0].publicationDate),
            'dd MMMM yyyy',
        )} out now`

        const editionLink = `/editions/${latestEdition[0].slug}`
        return {
            withGradientBackground: false,
            text: editionText,
            link: editionLink,
        }
    }

    return undefined
}

export const fetchNewsTickerCuration = async (config: BaseClientConfig) => {
    const url = `${config.contentApi}/v4/curation/news-ticker`
    const requestConfig = getRequestConfig(config)

    return await fetch(url, requestConfig).then((response) => response.json())
}

const fetchEdition = async (config: BaseClientConfig) => {
    const url = `${config.contentApi}/v4/edition`
    const requestConfig = getRequestConfig(config)

    return await fetch(url, requestConfig).then((response) => response.json())
}

const combineArticlesAndAdditional = (
    newTickerItems: PublicationCardItemWithoutVideo[],
    metadata: any,
): NewsTickerItem[] => {
    const newsTickerList = newTickerItems.map((article) => {
        const editorialType = getEditorialType(article, true)

        return {
            text: article.shortHeadline,
            link: article.link,
            type: editorialType,
            withGradientBackground: editorialType === EditorialType.Breaking,
        }
    })

    // Replace newList elements based on additional keys
    Object.keys(metadata).forEach((key) => {
        const match = key.match(/news-ticker-headline-(\d+)/)
        if (match) {
            const index = parseInt(match[1], 10)
            const replacementHeadline = metadata[key]
            if (
                index >= 0 &&
                index < newsTickerList.length &&
                replacementHeadline
            ) {
                newsTickerList[index].text = replacementHeadline
            }
        }
    })

    const breakingNewsTicker = newsTickerList.find(
        (newsTicker) => newsTicker.type === EditorialType.Breaking,
    )

    return breakingNewsTicker ? [breakingNewsTicker] : newsTickerList
}

const getRequestConfig = (config: BaseClientConfig) => {
    return {
        headers: {
            caller: config.apiCallerHeader,
        },
    }
}
